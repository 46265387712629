import { lazy, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { captureException } from '@sentry/react'
import { isPast } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

const Client = lazy(() => import('@/router/Client'))
const App = lazy(() => import('@/router/Admin'))

import Spinner from '@/components/Spinner'

import { JWT_NAMESPACE } from '@/lib/constants'
import type { GetSelfQuery } from '@/lib/generated/gql'
import GET_SELF from '@/lib/graphql/queries/getSelf'
import { ACCESS_TOKEN_KEY } from '@/utils/apollo/authContext'
import { getLoginCallback } from '@/utils/functions'

function LoginRedirect() {
	return <Navigate to={getLoginCallback(window.location)} />
}

export default function ProtectedRoutes() {
	const { i18n } = useTranslation()
	const token = localStorage.getItem(ACCESS_TOKEN_KEY)

	const { data, loading, error } = useQuery<GetSelfQuery>(GET_SELF, {
		fetchPolicy: 'cache-first',
		skip: !token,
		onCompleted(data) {
			// TODO: set tracking and more based on preferences
			if (
				data?.self?.preferences?.language &&
				data?.self?.preferences?.language !== i18n.language
			) {
				i18n.changeLanguage(data.self.preferences.language)
			}
		},
	})

	const self = data?.self

	return useMemo(() => {
		const decoded = token && jwtDecode<Record<string, any>>(token)

		if (!token || !decoded) {
			return <LoginRedirect />
		}

		const statements: string[] = decoded[JWT_NAMESPACE]?.statements

		const isTokenExpired =
			!decoded?.exp ||
			isNaN(decoded.exp) ||
			// eslint-disable-next-line no-magic-numbers
			isPast(new Date(decoded.exp * 1_000))

		if (isTokenExpired) return <LoginRedirect />

		if (!self && !statements) {
			if (loading) return <Spinner fullSize />
			if (error) captureException(error)
			return <LoginRedirect />
		}

		const showUseClientPortal = statements?.some((statement) => {
			const facilityId = statement?.split('/')?.[0]?.split(':')?.[2]

			if (!facilityId) return false
			if (facilityId === '*') return false

			return Boolean(facilityId)
		})

		const enabled = {
			modules: self?.organization?.apps ?? [],
			actions: statements?.map(
				(statement) => statement.split('/')[1].split(':')[0],
			),
			features: self?.organization?.features ?? [],
		}

		let Component = App
		if (showUseClientPortal) Component = Client

		return <Component enabled={enabled} />
	}, [token, self, error, loading])
}
